@mixin btn-bg {
  position: absolute;
  height: 90px;
  width: 90px;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  margin-left: -45px;
  margin-top: -45px;
  animation: grow 2s infinite;
  //   opacity: 0.2;
}

.btn {
  position: relative;
  width: 120px;
  height: 120px;
  outline: none;

  &__bg1 {
    @include btn-bg();
    background-color: rgba(33, 57, 145, 0.349);
  }

  &__bg2 {
    @include btn-bg();
    background-color: rgba(67, 83, 143, 0.226);
    animation-delay: 500ms;
  }

  &__bg3 {
    @include btn-bg();
    background-color: rgba(147, 184, 231, 0.219);
    animation-delay: 800ms;
  }

  button {
    position: absolute;
    border: 0;
    appearance: none;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
  }

  &__box {
    height: 25px;
    width: 25px;
    background-color: #213991;
    border-radius: 2px;
  }
}

.btn:active {
  button {
    background-color: #213991;
  }

  .btn__box {
    background-color: white;
  }
}

@keyframes grow {
  0% {
    height: 90px;
    width: 90px;
    margin-left: -45px;
    margin-top: -45px;
  }
  50% {
    height: 180px;
    width: 180px;
    margin-left: -90px;
    margin-top: -90px;
  }
  100% {
    height: 90px;
    width: 90px;
    margin-left: -45px;
    margin-top: -45px;
  }
}
