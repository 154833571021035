.Player {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 700px;
  height: 40vh;

  &__btn {
    position: absolute;
    // top: 0;
  }

  &__video {
    border-radius: 9px;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 400ms ease-in;
    outline: 0;
    // cursor: pointer;

    &--animation-enter {
      // left: 1000px;
    }

    &--animation-exit {
      // left: -1000px;
    }
  }
}
