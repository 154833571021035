.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px 40px;
  height: 100vh;
  width: 100vw;
  transition: background-color 300ms linear;
  overflow-x: hidden;
  min-width: 320px;

  video {
    width: 100%;
  }

  &__title {
    color: #213991;
    font-size: 36px;
    margin-top: 50px;
    font-weight: 900;
    text-align: center;
  }

  &__subtitle {
    color: #213991;
    font-size: 21px;
    margin-top: 15px;
    font-weight: 400;
    text-align: center;
  }

  &__actions {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: space-around;
    width: 160px;
  }

  &--recording {
    background-color: #213991;

    .App__title,
    .App__subtitle {
      color: white;
    }

    video {
      opacity: 0.5;
    }
  }
}
