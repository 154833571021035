@import "../../styles/mixins.scss";

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @include tablet() {
    align-items: center;
  }

  &__content {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    min-width: 100vw;
    padding: 20px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.767);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet() {
      min-width: 420px;
      border-radius: 18px;
    }
  }

  h1 {
    font-size: 20px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  button {
    align-self: center;
    margin-top: auto;
    appearance: none;
    border-radius: 25px;
    border: 0;
    background-color: black;
    color: white;
    padding: 12px 10px;
    width: 290px;
    // max-width: 300px;
    // min-width: 150px;
    cursor: pointer;

    @include tablet() {
      min-width: 150px;
    }
  }
}
