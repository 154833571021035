.btn {
  fill: #213991;
  appearance: none;
  background-color: transparent;
  border: 0;
  position: relative;
  width: 250px;
  height: 250px;
  cursor: pointer;

  svg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__container {
    margin-top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__circle {
    background-color: #213991;
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }

  &__text {
    color: #213991;
    font-size: 32px;
    font-weight: 900;
    margin-left: 6px;
  }

  &:active {
    outline: none;
  }
}

.btn:hover,
.btn:focus {
  .btn__circle {
    background-color: #eb5534;
  }

  .btn__text {
    color: #eb5534;
  }
}
