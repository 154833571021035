$blue: #213991;
$red: #eb5534;

.CircleButton {
  border: 0;
  appearance: none;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  svg {
    width: 100%;
  }

  &--blue {
    background-color: $blue;

    &:hover {
      background-color: lighten($color: $blue, $amount: 3);
    }

    &:focus {
      background-color: lighten($color: $blue, $amount: 6);
    }

    &:active {
      background-color: lighten($color: $blue, $amount: 10);
    }

    svg {
      fill: white;
    }
  }

  &--red {
    background-color: $red;

    &:hover {
      background-color: lighten($color: $red, $amount: 3);
    }

    &:focus {
      background-color: lighten($color: $red, $amount: 6);
    }

    &:active {
      background-color: lighten($color: $red, $amount: 10);
    }

    svg {
      fill: white;
    }
  }

  &:hover {
    cursor: pointer;
  }

  // &:active {
  //   box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.58);
  //   outline: none;
  // }

  // &:focus {
  //   box-shadow: 1px 1px 5px 0px rgb(0, 0, 0);
  // }
}
